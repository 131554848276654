<template>
    <div class="common-layout">
        <div class="content">
            <div>
                <div class="top">
                    <div class="header">
                        <img alt="logo" class="logo" src="../assets/images/minitable-logo.png" />
                        <span class="title">Sign in</span>
                    </div>
                    <div class="desc">Minitable Group Management</div>
                </div>
                <div class="login">
                    <a-form>
                        <a-form-item>
                            <a-input
                                v-model:value="user_id"
                                autocomplete="autocomplete"
                                size="large"
                                placeholder="Username"
                            >
                                <template #prefix>
                                    <UserOutlined />
                                </template>
                            </a-input>
                        </a-form-item>
                        <a-form-item>
                            <a-input
                                v-model:value="pswd"
                                size="large"
                                placeholder="Password"
                                autocomplete="autocomplete"
                                type="password"
                            >
                                <template #prefix>
                                    <LockOutlined />
                                </template>
                            </a-input>
                        </a-form-item>
                        <a-form-item>
                            <a-button :loading="logging" style="width: 100%; margin-top: 24px" size="large" type="primary" @click="do_login">Sign in</a-button>
                        </a-form-item>
                    </a-form>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import md5 from 'md5';
    import { mapActions } from 'vuex';

    import {
        UserOutlined,
        LockOutlined,
    } from '@ant-design/icons-vue';

    export default {
        name: 'LoginPage',
        components: {
            UserOutlined,
            LockOutlined,
        },
        data() {
            return {
                logging: false,
                user_id: '',
                pswd: ''
            };
        },
        beforeMount() {
            let _token = window.localStorage.getItem('token') || '';
            if (_token) {
                this.$router.replace({ path: '/setting' });
            }
        },
        computed: {},
        methods: {
            do_login: function () {
                if (!this.user_id || !this.pswd) {
                    this.$notification['warning']({
                        message: 'Please enter your username and password'
                    });
                    return;
                }
                this.logging = true;
                this.do_login_aj({
                    user_id: this.user_id,
                    pswd: md5(this.pswd),
                    router: this.$router
                }).then(() => {
                    this.logging = false;
                });
            },
            ...mapActions({
                do_login_aj: 'login/do_login_aj'
            })
        }
    };
</script>

<style scoped>
    .common-layout {
        display: flex;
        overflow: auto;
        flex-direction: column;
        height: 100vh;
        background-position-x: center;
        background-position-y: 110px;
        background-size: 100%;
        background-repeat: no-repeat;
    }

    .content {
        flex: 1;
        padding: 32px 0;
        @media (min-width: 768px) {
            padding: 112px 0 24px;
        }
    }

    .top {
        text-align: center;
    }

    .header {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 44px;
        line-height: 44px;
    }

    a {
        text-decoration: none;
    }
    .logo {
        margin-right: 16px;
        height: 44px;
        vertical-align: top;
    }
    .title {
        position: relative;
        top: 2px;
        font-weight: 600;
        font-size: 33px;
    }

    .desc {
        margin-top: 12px;
        margin-bottom: 40px;
        font-size: 14px;
    }

    .login {
        margin: 0 auto;
        width: 368px;
        @media screen and (max-width: 576px) {
            width: 95%;
        }
        @media screen and (max-width: 320px) {
            .captcha-button {
                font-size: 14px;
            }
        }
    }

    .login .icon {
        margin-left: 16px;
        vertical-align: middle;
        font-size: 24px;
        cursor: pointer;
        transition: color 0.3s;
    }
</style>
