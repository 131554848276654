import { createRouter, createWebHistory } from 'vue-router';

import Login from '../views/Login.vue';
import Locations from '../views/Locations.vue';
import Setting from '../views/Setting.vue';
import Survey from '../views/Survey.vue';
import InsightHome from '../views/Insight/Home.vue';
import InsightWaitlist from '../views/Insight/Waitlist.vue';
import InsightReserve from '../views/Insight/Reserve.vue';
import InsightNotification from '../views/Insight/Notification.vue';

const routes = [
    { path: '/login', name: 'login', component: Login },
    { path: '/locations', name: 'locations', component: Locations },
    { path: '/setting', name: 'setting', component: Setting },
    { path: '/survey', name: 'survey', component: Survey },
    { path: '/insight/home', name: 'insight-home', component: InsightHome },
    { path: '/insight/waitlist', name: 'insight-waitlist', component: InsightWaitlist },
    { path: '/insight/reserve', name: 'insight-reserve', component: InsightReserve },
    { path: '/insight/notification', name: 'insight-notification', component: InsightNotification },
    
    { path: '/', redirect: '/login' },
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

export default router
