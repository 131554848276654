import axiosApi from '../../kits/axiosApi';

// initial state
const state = {};

// getters
const getters = {};

// actions
const actions = {
    do_login_aj({ commit, rootState }, post_data) {
        let aj_host = rootState.aj_host;
        return axiosApi
            .get(aj_host + '/weapp/master/user/login', {
                params: {
                    user_id: post_data.user_id,
                    pswd: post_data.pswd
                }
            })
            .then((res) => {
                if (res.data.data.code == 100000) {
                    commit('app/APP_SET_GLOBAL_DATA', res.data.data.data, { root: true });
                    window.localStorage.setItem('chain_id', res.data.data.data.chain_id);
                    window.localStorage.setItem('chain_name', res.data.data.data.chain_name);
                    window.localStorage.setItem('token', res.data.data.data.token);
                    window.localStorage.setItem('user_name', res.data.data.data.user_name);
                    window.localStorage.setItem('role', res.data.data.data.role);
                    window.localStorage.setItem('cluster', res.data.data.data.cluster_name);

                    post_data.router.replace({ path: '/insight/home' });
                }
            })
            .catch((err) => {
                console.log(err);
            });
    }
};

// mutations
const mutations = {};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
