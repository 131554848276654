import { createStore, createLogger } from 'vuex';
import app from './modules/app';
import login from './modules/login';
import survey from './modules/survey';
import insight from './modules/insight';

let aj_host_datacenter;
if (window.location.hostname.indexOf('.net') > -1) {
    aj_host_datacenter = 'https://datacenter.minitable.net';
} else {
    aj_host_datacenter = 'https://datacenter.minitable.link';
}

const state = {
    debug:
        window.location.hostname === '127.0.0.1' ||
        window.location.hostname === 'localhost',
    aj_host: window.location.hostname === '127.0.0.1' || window.location.hostname === 'localhost' ? 'http://127.0.0.1:7272' : '',
    aj_host_datacenter: aj_host_datacenter
};

export default createStore({
    modules: {
        app,
        login,
        survey,
        insight,
    },
    state,
    strict: state.debug,
    plugins: state.debug ? [createLogger()] : []
})