import axiosApi from '../../kits/axiosApi';

let data_center_host = window.location.hostname.replace('master', 'datacenter');
data_center_host = window.location.hostname === '127.0.0.1' ? 'datacenter.minitable.link' : data_center_host;

const state = {};
const getters = {};

const do_proxy_aj = async function(host, params) {
    host = 'https://admin.minitable.link';
    return new Promise((resolve, reject) => {
        axiosApi
            .post(host + '/weapp/admin/proxy', params)
            .then(res => {
                let res_data = res.data.data.data;
                if (res_data.data.data) {
                    resolve(res_data.data);
                } else {
                    resolve(res_data);
                }
            })
            .catch(err => {
                reject(err);
            });
    });
};

const actions = {
    async get_home_dashboard({ rootState }, params) {
        let aj_host = rootState.aj_host;

        return await do_proxy_aj(aj_host,  {
            url: 'https://' + data_center_host + '/boss/home/dashboard?sid=' + params.sid + '&cluster=' + params.cluster,
            param: params,
            method: 'get'
        });
    },
    async get_home_snapshot({ rootState }, params) {
        let aj_host = rootState.aj_host;

        return await do_proxy_aj(aj_host,  {
            url: 'https://' + data_center_host + '/boss/home/snapshot?sid=' + params.sid + '&cluster=' + params.cluster + '&type=' + params.type,
            param: params,
            method: 'get'
        });
    },
    async get_waitlist_graph({ rootState }, params) {
        let aj_host = rootState.aj_host;

        return await do_proxy_aj(aj_host,  {
            url: 'https://' + data_center_host + '/boss/wait/graph?sid=' + params.sid + '&cluster=' + params.cluster + '&sdate=' + params.sdate + '&edate=' + params.edate + '&type=' + params.type + '&source=' + params.source,
            param: params,
            method: 'get'
        });
    },
    async get_waitlist_dashboard({ rootState }, params) {
        let aj_host = rootState.aj_host;

        return await do_proxy_aj(aj_host,  {
            url: 'https://' + data_center_host + '/boss/wait/dashboard?sid=' + params.sid + '&cluster=' + params.cluster + '&sdate=' + params.sdate + '&edate=' + params.edate,
            param: params,
            method: 'get'
        });
    },
    async get_waitlist_source({ rootState }, params) {
        let aj_host = rootState.aj_host;

        return await do_proxy_aj(aj_host,  {
            url: 'https://' + data_center_host + '/boss/wait/source?sid=' + params.sid + '&cluster=' + params.cluster + '&sdate=' + params.sdate + '&edate=' + params.edate,
            param: params,
            method: 'get'
        });
    },
    async get_waitlist_time({ rootState }, params) {
        let aj_host = rootState.aj_host;

        return await do_proxy_aj(aj_host,  {
            url: 'https://' + data_center_host + '/boss/wait/time?sid=' + params.sid + '&cluster=' + params.cluster + '&sdate=' + params.sdate + '&edate=' + params.edate,
            param: params,
            method: 'get'
        });
    },
    async get_waitlist_party({ rootState }, params) {
        let aj_host = rootState.aj_host;

        return await do_proxy_aj(aj_host,  {
            url: 'https://' + data_center_host + '/boss/wait/party?sid=' + params.sid + '&cluster=' + params.cluster + '&sdate=' + params.sdate + '&edate=' + params.edate,
            param: params,
            method: 'get'
        });
    },
    async get_reserve_graph({ rootState }, params) {
        let aj_host = rootState.aj_host;

        return await do_proxy_aj(aj_host,  {
            url: 'https://' + data_center_host + '/boss/reserve/graph?sid=' + params.sid + '&cluster=' + params.cluster + '&sdate=' + params.sdate + '&edate=' + params.edate + '&type=' + params.type + '&source=' + params.source,
            param: params,
            method: 'get'
        });
    },
    async get_reserve_dashboard({ rootState }, params) {
        let aj_host = rootState.aj_host;

        return await do_proxy_aj(aj_host,  {
            url: 'https://' + data_center_host + '/boss/reserve/dashboard?sid=' + params.sid + '&cluster=' + params.cluster + '&sdate=' + params.sdate + '&edate=' + params.edate,
            param: params,
            method: 'get'
        });
    },
    async get_reserve_source({ rootState }, params) {
        let aj_host = rootState.aj_host;

        return await do_proxy_aj(aj_host,  {
            url: 'https://' + data_center_host + '/boss/reserve/source?sid=' + params.sid + '&cluster=' + params.cluster + '&sdate=' + params.sdate + '&edate=' + params.edate,
            param: params,
            method: 'get'
        });
    },
    async get_reserve_time({ rootState }, params) {
        let aj_host = rootState.aj_host;

        return await do_proxy_aj(aj_host,  {
            url: 'https://' + data_center_host + '/boss/reserve/time?sid=' + params.sid + '&cluster=' + params.cluster + '&sdate=' + params.sdate + '&edate=' + params.edate,
            param: params,
            method: 'get'
        });
    },
    async get_reserve_party({ rootState }, params) {
        let aj_host = rootState.aj_host;

        return await do_proxy_aj(aj_host,  {
            url: 'https://' + data_center_host + '/boss/reserve/party?sid=' + params.sid + '&cluster=' + params.cluster + '&sdate=' + params.sdate + '&edate=' + params.edate,
            param: params,
            method: 'get'
        });
    },
    async get_guest_graph({ rootState }, params) {
        let aj_host = rootState.aj_host;

        return await do_proxy_aj(aj_host,  {
            url: 'https://' + data_center_host + '/boss/guest/graph?sid=' + params.sid + '&cluster=' + params.cluster + '&sdate=' + params.sdate + '&edate=' + params.edate + '&type=' + params.type,
            param: params,
            method: 'get'
        });
    },
    async get_notification_list({ rootState }, post_data) {
        let aj_host = rootState.aj_host;

        return new Promise((resolve, reject) => {
            axiosApi
                .get(aj_host + `/weapp/master/insight/notification/list?page=${post_data.page}&page_size=${post_data.page_size}&sdate=${post_data.sdate}&edate=${post_data.edate}&type=${post_data.type}&sid=${post_data.sid}`)
                .then((res) => {
                    resolve(res.data.data)
                })
                .catch((err) => {
                    reject(err)
                })
        })
    },
    async get_notification_dashbaord({ rootState }, post_data) {
        let aj_host = rootState.aj_host;
        
        return new Promise((resolve, reject) => {
            axiosApi
                .get(aj_host + `/weapp/master/insight/notification/dashboard?sdate=${post_data.sdate}&edate=${post_data.edate}&type=${post_data.type}&sid=${post_data.sid}`)
                .then((res) => {
                    resolve(res.data.data)
                })
                .catch((err) => {
                    reject(err)
                })
        })
    }
};

const mutations = {};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}