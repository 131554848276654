import axiosApi from '../../kits/axiosApi';

// initial state
const state = {
    show_loading: false,
    token: window.localStorage.getItem('token') || '',
    is_mobile: document.body.clientWidth < 768,
    version: '0.0.1',
    build_number: 1,
    slogo: 'https://d3puj4wh202ha4.cloudfront.net/minipass/images/group.jpg',
    role: window.localStorage.getItem('role') || '',
    user_name: window.localStorage.getItem('user_name') || '',
    timezone: window.localStorage.getItem('timezone') || '',
    chain_name: window.localStorage.getItem('chain_name') || '',
    chain_id: window.localStorage.getItem('chain_id') || '',
    store_list: [],
    cluster: window.localStorage.getItem('cluster') || 'north_america',
    select_sid: window.localStorage.getItem('select_sid') || '',
    can_notification: window.localStorage.getItem('can_notification') || 0,
};

// getters
const getters = {
    get_select_sid() {
        return state.select_sid;
    },
    get_chain_id() {
        return state.chain_id;
    },
    get_user_name() {
        return state.user_name;
    },
    get_role() {
        return state.role;
    },
    get_loading(state) {
        return state.show_loading;
    },
    get_timezone(state) {
        return state.timezone || 'America/New_York';
    },
    get_slogo(state) {
        return state.slogo || '';
    },
    get_chain_name(state) {
        return state.chain_name || '';
    },
};

// actions
const actions = {
    initData({ rootState }, post_data) {
        let aj_host = rootState.debug ? "http://127.0.0.1:9090" : "";

        return new Promise((resolve, reject) => {
            axiosApi
                .post(aj_host + "/weapp/test", post_data)
                .then(res => {
                    if (res.data.data && res.data.data.code == 100000) {
                        resolve(res.data.data)
                    } else {
                        console.log(res.data.data);
                    }
                })
                .catch(err => {
                    reject(err)
                });
        })
    },
    getStoreList({ rootState, commit }, params) {
        let aj_host = rootState.aj_host;

        return new Promise((resolve, reject) => {
            // if (JSON.stringify(rootState.app.store_list) != '[]') {
            //     resolve(rootState.app.store_list);
            //     return;
            // }

            axiosApi
                .get(aj_host + '/weapp/admin/store/get', { params })
                .then((res) => {
                    if (res.data.data.data.list) {
                        resolve(res.data.data.data.list);
                        commit('APP_STORE', res.data.data.data.list);
                    } else {
                        resolve([]);
                        commit('APP_STORE', []);
                    }
                })
                .catch((err) => {
                    reject(err);
                });
        });
    },
    editStoreName({ rootState, commit }, post_data) {
        let aj_host = rootState.aj_host;

        return new Promise((resolve, reject) => {
            axiosApi
                .post(aj_host + '/weapp/admin/store/nickname/update', post_data)
                .then((res) => {
                    resolve(res.data.data);
                    commit('APP_STORE', []);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    },
};

// mutations
const mutations = {
    APP_SET_LOADING(state, _data) {
        state.show_loading = _data.show_loading;
    },
    APP_SET_GLOBAL_DATA(state, _data) {
        state.sname = _data.sname;
        state.token = _data.token;
        state.user_name = _data.user_name;
        state.chain_name = _data.chain_name;
        state.chain_id = _data.chain_id;
        state.cluster = _data.cluster;
    },
    APP_STORE(state, _data) {
        state.store_list = _data;
    },
    APP_SET_SELECT_SID(state, _data) {
        if (_data) {
            state.select_sid = _data;
            window.localStorage.setItem('select_sid', _data);
        }
    }
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
