<template>
    <div class="insight">
        <a-row style="background-color: #fff;">
            <a-col :md="{ span: 18 }" :xs="{ span: 24 }">
                <a-menu v-model:selectedKeys="current" mode="horizontal" :items="items" />
            </a-col>
            <a-col :md="{ span: 6 }" :xs="{ span: 24 }" style="padding-top: 6px; padding-right: 5px;" v-if="store_options.length > 0">
                <a-select
                    v-model:value="sid"
                    :options="store_options"
                    style="width: 300px;"
                    @change="handleChangeStore"
                >
                </a-select>
            </a-col>
        </a-row>
        <a-row style="margin: 10px 0;">
            <a-col :md="{ span: 12 }" :xs="{ span: 24 }" style="padding-right: 5px; height: 320px;">
                <a-card :title="$t('insight.dashboard')" :bordered="false" style="width: 100%; height: 100%;">
                    <a-row :gutter="16">
                        <a-skeleton active v-if="dashboad_loading"></a-skeleton>
                        <a-col :span="12" v-for="(item, index) in dashboard_cards" v-bind:key="item.type" :style="{marginTop: index > 1 ? '5px': '0px'}">
                            <a-card :bodyStyle="{paddingLeft: '10px'}" style="width: 100%;background-color: #eee;">
                                <a-card-meta :title="mapper['dashboard'][item.type].text" :description="mapper['dashboard'][item.type].sub_text == 'diners' ? '(' + item.sub_value + ' diners)' : mapper['dashboard'][item.type].sub_text">
                                    <template #avatar>
                                        <TeamOutlined style="font-size: 28px;" v-if="mapper['dashboard'][item.type].icon == 'team' && !is_mobile" />
                                        <ClockCircleOutlined style="font-size: 28px;" v-if="mapper['dashboard'][item.type].icon == 'clock-circle' && !is_mobile" />
                                        <ScheduleOutlined style="font-size: 28px;" v-if="mapper['dashboard'][item.type].icon == 'schedule' && !is_mobile" />
                                        <ContactsOutlined style="font-size: 28px;" v-if="mapper['dashboard'][item.type].icon == 'contacts' && !is_mobile" />
                                        <!-- <a-icon style="font-size: 28px;" :type="mapper['dashboard'][item.type].icon"></a-icon> -->
                                        <h3 style="display: inline-block;font-size: 33px; margin-left: 5px;">{{ item.value }}</h3>
                                    </template>
                                </a-card-meta>
                            </a-card>
                        </a-col>
                    </a-row>
                </a-card>
            </a-col>
            <a-col :md="{ span: 12 }" :xs="{ span: 24 }" style="height: 320px;">
                <a-card :title="$t('insight.snapshot')" :bordered="false" style="width: 100%; height: 100%; font-weight: bold;">
                    <template #extra>
                        <a-select v-model:value="snap_type" style="width: 120px" @change="handleSnapChange">
                            <a-select-option value="today">
                                {{ $t('insight.today') }}
                            </a-select-option>
                            <a-select-option value="this_week">
                                {{ $t('insight.this_week') }}
                            </a-select-option>
                            <a-select-option value="this_month">
                                {{ $t('insight.this_month') }}
                            </a-select-option>
                            <a-select-option value="this_year">
                                {{ $t('insight.this_year') }}
                            </a-select-option>
                        </a-select>
                    </template>
                    <a-skeleton active v-if="snapshot_loading"></a-skeleton>
                    <a-row v-else>
                        <a-col :span="6">
                            <div style="text-align: center; padding-top: 40px;">
                                <h2>{{ snapshot_total }}</h2>
                                <p style="margin-bottom: 0;">{{ $t('insight.total_parties') }}</p>
                            </div>
                        </a-col>
                        <a-col :span="18" style="text-align: center; font-size: 15px;">
                            <a-row>
                                <a-col :span="8" v-for="item in snapshot_cards_1" v-bind:key="item.name">
                                    <p>{{ $t('insight.' + (item.name.toLowerCase())) }}</p>
                                    <p>{{ item.value }}</p>
                                    <p>{{ item.sub_value }}%</p>
                                </a-col>
                            </a-row>
                            <a-divider></a-divider>
                            <a-row>
                                <a-col :span="8" v-for="item in snapshot_cards_2" v-bind:key="item.name">
                                    <p>{{ $t('insight.' + (item.name.toLowerCase())) }}</p>
                                    <p>{{ item.value }}</p>
                                    <p>{{ item.sub_value }}%</p>
                                </a-col>
                            </a-row>
                        </a-col>
                    </a-row>
                </a-card>
            </a-col>
        </a-row>
        <a-row style="margin: 10px 0;">
            <a-col :span="24" style="padding-right: 5px; height: 100%;">
                <a-card :title="$t('insight.number_of_waitlists')" :bordered="false" style="width: 100%; height: 100%;">
                    <template #extra v-if="!is_mobile">
                        <a-range-picker @change="onDateChange('waitlist')" v-model:value="waitlist_date" :format="dateFormat"></a-range-picker>
                        <a-select v-model:value="waitlist_graph_type" style="width: 120px" @change="handleGraphChange('waitlist', $event)">
                            <a-select-option value="daily">
                                {{ $t('insight.daily') }}
                            </a-select-option>
                            <a-select-option value="week">
                                {{ $t('insight.weekly') }}
                            </a-select-option>
                            <a-select-option value="month">
                                {{ $t('insight.monthly') }}
                            </a-select-option>
                        </a-select>
                    </template>
                    <div v-if="is_mobile">
                        <a-range-picker @change="onDateChange('waitlist')" v-model:value="waitlist_date" :format="dateFormat"></a-range-picker>
                        <a-select v-model:value="waitlist_graph_type" style="width: 120px" @change="handleGraphChange('waitlist', $event)">
                            <a-select-option value="daily">
                                {{ $t('insight.daily') }}
                            </a-select-option>
                            <a-select-option value="week">
                                {{ $t('insight.weekly') }}
                            </a-select-option>
                            <a-select-option value="month">
                                {{ $t('insight.monthly') }}
                            </a-select-option>
                        </a-select>
                    </div>
                    <div class="chart" style="height: 300px;" id="waitlist_graph_chart"></div>
                </a-card>
            </a-col>
        </a-row>
        <a-row style="margin: 10px 0;">
            <a-col :span="24" style="height: 100%;">
                <a-card :title="$t('insight.number_of_reservations')" :bordered="false" style="width: 100%; height: 100%;">
                    <template #extra v-if="!is_mobile">
                        <a-range-picker @change="onDateChange('reserve')" v-model:value="reserve_date" :format="dateFormat"></a-range-picker>
                        <a-select v-model:value="reserve_graph_type" style="width: 120px" @change="handleGraphChange('reserve', $event)">
                            <a-select-option value="daily">
                                {{ $t('insight.daily') }}
                            </a-select-option>
                            <a-select-option value="week">
                                {{ $t('insight.weekly') }}
                            </a-select-option>
                            <a-select-option value="month">
                                {{ $t('insight.monthly') }}
                            </a-select-option>
                        </a-select>
                    </template>
                    <div v-if="is_mobile">
                        <a-range-picker @change="onDateChange('reserve')" v-model:value="reserve_date" :format="dateFormat"></a-range-picker>
                        <a-select v-model:value="reserve_graph_type" style="width: 120px" @change="handleGraphChange('reserve', $event)">
                            <a-select-option value="daily">
                                {{ $t('insight.daily') }}
                            </a-select-option>
                            <a-select-option value="week">
                                {{ $t('insight.weekly') }}
                            </a-select-option>
                            <a-select-option value="month">
                                {{ $t('insight.monthly') }}
                            </a-select-option>
                        </a-select>
                    </div>
                    <div class="chart" style="height: 300px;" id="reserve_graph_chart"></div>
                </a-card>
            </a-col>
        </a-row>
        <a-row style="margin: 10px 0;">
            <a-col :span="24" style="height: 100%;">
                <a-card :title="$t('insight.number_of_new_guests')" :bordered="false" style="width: 100%; height: 100%;">
                    <template #extra v-if="!is_mobile">
                        <a-range-picker @change="onDateChange('guest')" v-model:value="guest_date" :format="dateFormat"></a-range-picker>
                        <a-select v-model:value="guest_graph_type" style="width: 120px" @change="handleGraphChange('guest', $event)">
                            <a-select-option value="daily">
                                {{ $t('insight.daily') }}
                            </a-select-option>
                            <a-select-option value="week">
                                {{ $t('insight.weekly') }}
                            </a-select-option>
                            <a-select-option value="month">
                                {{ $t('insight.monthly') }}
                            </a-select-option>
                        </a-select>
                    </template>
                    <div v-if="is_mobile">
                        <a-range-picker @change="onDateChange('guest')" v-model:value="guest_date" :format="dateFormat"></a-range-picker>
                        <a-select v-model:value="guest_graph_type" style="width: 120px" @change="handleGraphChange('guest', $event)">
                            <a-select-option value="daily">
                                {{ $t('insight.daily') }}
                            </a-select-option>
                            <a-select-option value="week">
                                {{ $t('insight.weekly') }}
                            </a-select-option>
                            <a-select-option value="month">
                                {{ $t('insight.monthly') }}
                            </a-select-option>
                        </a-select>
                    </div>
                    <div class="chart" style="height: 300px;" id="guests_graph_chart"></div>
                </a-card>
            </a-col>
        </a-row>
    </div>
</template>
<script>
    import { mapState, mapActions, mapGetters, mapMutations } from "vuex";
    import * as echarts from 'echarts';
    import moment from 'moment-timezone';
    import { h } from 'vue';
    import dayjs from 'dayjs';

    import {
        MailOutlined,
        TeamOutlined,
        GlobalOutlined,
        ClockCircleOutlined,
        ScheduleOutlined,
        ContactsOutlined,
    } from '@ant-design/icons-vue';

    export default {
        name: "insight_home",
        components: {
            TeamOutlined,
            ClockCircleOutlined,
            ScheduleOutlined,
            ContactsOutlined,
        },
        data() {
            return {
                items: [
                    {
                        key: 'summary',
                        icon: () => h(GlobalOutlined),
                        label: this.$t('insight.summary'),
                        title: 'Summary',
                    },
                    {
                        key: 'waitlist',
                        icon: () => h(TeamOutlined),
                        label: this.$t('insight.waitlist'),
                        title: 'Waitlist',
                    },
                    {
                        key: 'reserve',
                        icon: () => h(ClockCircleOutlined),
                        label: this.$t('insight.reserve'),
                        title: 'Reserve',
                    },
                    {
                        key: 'notification',
                        icon: () => h(MailOutlined),
                        label: this.$t('insight.notification'),
                        title: 'Notification',
                    },
                ],
                store_options: [],
                sid: this.$store.state.app.select_sid,
                chain_id: this.$store.state.app.chain_id,
                cluster: this.$store.state.app.cluster,
                is_mobile: this.$store.state.app.is_mobile,
                current: ['summary'],
                waitlistGraphChart: null,
                snap_type: 'today',
                waitlist_graph_type: 'daily',
                reserve_graph_type: 'daily',
                guest_graph_type: 'daily',
                dateFormat: 'YYYY-MM-DD',
                moment: moment,
                dashboard_cards: [],
                snapshot_cards_1: [],
                snapshot_cards_2: [],
                snapshot_total: 0,
                dashboad_loading: false,
                snapshot_loading: false,
                waitlist_graph_loading: false,
                reserve_graph_loading: false,
                guest_graph_loading: false,

                guest_date: [],
                waitlist_date: [],
                reserve_date: [],

                waitlist_graph_data: null,
                reserve_graph_data: null,
                guest_graph_data: null,

                mapper: {
                    dashboard: {
                        wait_summary: {
                            text: this.$t('insight.waiting_parties'),
                            sub_text: this.$t('insight.diners'),
                            icon: 'team'
                        },
                        wait_time: {
                            text: this.$t('insight.current_waiting_time'),
                            sub_text: this.$t('insight.mins'),
                            icon: 'clock-circle'
                        },
                        reservation_summary: {
                            text: this.$t('insight.reserved_parties'),
                            sub_text: this.$t('insight.diners'),
                            icon: 'schedule'
                        },
                        serving_summary: {
                            text: this.$t('insight.current_serving_parties'),
                            sub_text: this.$t('insight.diners'),
                            icon: 'contacts'
                        },
                    }
                }
            }
        },
        async beforeMount() {
            let sdate = dayjs(moment().subtract(30, 'days').format(this.dateFormat), this.dateFormat)
            let edate = dayjs(moment().subtract(0, 'days').format(this.dateFormat), this.dateFormat)

            this.guest_date = [sdate, edate];
            this.waitlist_date = [sdate, edate];
            this.reserve_date = [sdate, edate];
        },
        async mounted() {
            this.do_get_store_list();
        },
        watch: {
            current() {
                if (this.current == 'waitlist') {
                    this.$router.push({
                        path: '/insight/waitlist'
                    });
                } else if (this.current == 'reserve') {
                    this.$router.push({
                        path: '/insight/reserve'
                    });
                } else if (this.current == 'notification') {
                    this.$router.push({
                        path: '/insight/notification'
                    })
                }
            },
        },
        computed: {
            ...mapGetters({
            }),
            ...mapState({
                updateTime: state => state.dashboard.update_time
            }),
            waitlistGraphOption() {
                let option = {
                    title: {
                        show: false,
                        text: '2024 Jun 07 - 2024 Jun 07',
                        left: 'center',
                        textStyle: {
                            color: '#333',
                            fontSize: 14,
                        },
                    },
                    xAxis: {
                        data: []
                    },
                    yAxis: {
                    },
                    tooltip: {
                        show: true,
                        trigger: 'item'
                    },
                    series: [
                        {
                            type: 'bar',
                            data: []
                        }
                    ]
                };

                if (this.waitlist_graph_data) {
                    option.xAxis.data = this.waitlist_graph_data.label;
                    option.series[0].data = this.waitlist_graph_data.data;
                }

                return option;
            },
            reserveGraphOption() {
                let option = {
                    title: {
                        show: false,
                        text: '2024 Jun 07 - 2024 Jun 07',
                        left: 'center',
                        textStyle: {
                            color: '#333',
                            fontSize: 14,
                        },
                    },
                    xAxis: {
                        data: []
                    },
                    yAxis: {},
                    tooltip: {
                        show: true,
                        trigger: 'item'
                    },
                    series: [
                        {
                            type: 'bar',
                            data: []
                        }
                    ]
                }

                if (this.reserve_graph_data) {
                    option.xAxis.data = this.reserve_graph_data.label;
                    option.series[0].data = this.reserve_graph_data.data;
                }

                return option;
            },
            guestsGraphOption() {
                let option = {
                    title: {
                        show: false,
                        text: '2024 Jun 07 - 2024 Jun 07',
                        left: 'center',
                        textStyle: {
                            color: '#333',
                            fontSize: 14,
                        },
                    },
                    xAxis: {
                        data: []
                    },
                    yAxis: {},
                    tooltip: {
                        show: true,
                        trigger: 'item'
                    },
                    series: [
                        {
                            type: 'bar',
                            data: []
                        }
                    ]
                }

                if (this.guest_graph_data) {
                    option.xAxis.data = this.guest_graph_data.label;
                    option.series[0].data = this.guest_graph_data.data;
                }

                return option;
            },
        },
        methods: {
            ...mapActions({
                get_home_dashboard: "insight/get_home_dashboard",
                get_home_snapshot: "insight/get_home_snapshot",
                get_waitlist_graph: "insight/get_waitlist_graph",
                get_reserve_graph: "insight/get_reserve_graph",
                get_guest_graph: "insight/get_guest_graph",
                getStoreList: 'app/getStoreList',
            }),
            ...mapMutations({
                setSelectSid: 'app/APP_SET_SELECT_SID'
            }),
            handleChangeStore() {
                this.setSelectSid(this.sid);
                this.request_data();
            },
            async do_get_store_list() {
                let store_list = await this.getStoreList({
                    chain_id: this.chain_id,
                    page: 1,
                    size: 0
                });

                this.store_options = store_list.map(item => {
                    return {
                        value: item.sid,
                        label: item.nick_name,
                    };
                });

                if (!this.sid) {
                    this.setSelectSid(this.store_options[0] && this.store_options[0].value || '');
                    this.sid = this.store_options[0] && this.store_options[0].value || '';
                }
                this.request_data();
            },
            async request_data() {
                this.request_dashboard();
                this.request_snapshot();
                this.request_waitlist_graph();
                this.request_reserve_graph();
                this.request_guest_graph();
            },
            async request_dashboard() {
                this.dashboad_loading = true;
                let home_dashboard_data = await this.get_home_dashboard({
                    sid: this.sid,
                    cluster: this.cluster
                });

                if (home_dashboard_data.code === 100000) {
                    let data_cards = home_dashboard_data.data.cards;
                    data_cards.splice(4, 2);
                    this.dashboard_cards = data_cards;
                }

                this.dashboad_loading = false;
            },
            async request_snapshot() {
                this.snapshot_loading = true;
                let home_snapshot_data = await this.get_home_snapshot({
                    sid: this.sid,
                    cluster: this.cluster,
                    type: this.snap_type
                });

                if (home_snapshot_data.code === 100000) {
                    let data_cards = home_snapshot_data.data.cards;
                    this.snapshot_cards_1 = data_cards[0];
                    this.snapshot_cards_2 = data_cards[1];

                    let total = this.snapshot_cards_1[0].value + this.snapshot_cards_2[0].value;
                    this.snapshot_total = total;
                }

                this.snapshot_loading = false;
            },
            async request_waitlist_graph() {
                this.waitlist_graph_loading = true;
                let waitlist_graph_data = await this.get_waitlist_graph({
                    sid: this.sid,
                    cluster: this.cluster,
                    type: this.waitlist_graph_type,
                    sdate: dayjs(this.waitlist_date[0]).format(this.dateFormat),
                    edate: dayjs(this.waitlist_date[1]).format(this.dateFormat),
                    source: ''
                });

                if (waitlist_graph_data.code === 100000) {
                    this.waitlist_graph_data = waitlist_graph_data.data;
                    this.render_waitlist_graph();
                }

                this.waitlist_graph_loading = false;
            },
            async request_reserve_graph() {
                this.reserve_graph_loading = true;
                let reserve_graph_data = await this.get_reserve_graph({
                    sid: this.sid,
                    cluster: this.cluster,
                    type: this.reserve_graph_type,
                    sdate: dayjs(this.reserve_date[0]).format(this.dateFormat),
                    edate: dayjs(this.reserve_date[1]).format(this.dateFormat),
                    source: ''
                });

                if (reserve_graph_data.code === 100000) {
                    this.reserve_graph_data = reserve_graph_data.data;
                    this.render_reserve_graph();
                }

                this.reserve_graph_loading = false;
            },
            async request_guest_graph() {
                this.guest_graph_loading = true;
                let guest_graph_data = await this.get_guest_graph({
                    sid: this.sid,
                    cluster: this.cluster,
                    type: this.guest_graph_type,
                    sdate: dayjs(this.guest_date[0]).format(this.dateFormat),
                    edate: dayjs(this.guest_date[1]).format(this.dateFormat),
                });

                if (guest_graph_data.code === 100000) {
                    this.guest_graph_data = guest_graph_data.data;
                    this.render_guest_graph();
                }

                this.guest_graph_loading = false;
            },
            render_waitlist_graph() {
                var chartDom = document.getElementById('waitlist_graph_chart');
                this.waitlistGraphChart = echarts.init(chartDom);
                
                this.waitlistGraphOption && this.waitlistGraphChart.setOption(this.waitlistGraphOption);
            },
            render_reserve_graph() {
                var chartDom = document.getElementById('reserve_graph_chart');
                this.reserveGraphChart = echarts.init(chartDom);
                
                this.reserveGraphOption && this.reserveGraphChart.setOption(this.reserveGraphOption);
            },
            render_guest_graph() {
                var chartDom = document.getElementById('guests_graph_chart');
                this.guestsGraphChart = echarts.init(chartDom);
                
                this.guestsGraphOption && this.guestsGraphChart.setOption(this.guestsGraphOption);
            },
            handleSnapChange() {
                this.request_snapshot();
            },
            handleGraphChange(type, value) {
                this[type + '_graph_type'] = value;

                if (type == 'waitlist') {
                    this.request_waitlist_graph();
                } else if(type == 'reserve') {
                    this.request_reserve_graph();
                } else {
                    this.request_guest_graph();
                }
            },
            onDateChange(type) {
                if (type == 'waitlist') {
                    this.request_waitlist_graph();
                } else if(type == 'reserve') {
                    this.request_reserve_graph();
                } else {
                    this.request_guest_graph();
                }
            },
        }
    }
</script>

<style>
    .insight {
        height: 97vh;
        overflow: auto;
    }
    .insight .ant-card-meta-title{
        font-size: 12px;
    }
    .insight .ant-card-body {
        padding-left: 10px;
    }
    .insight p {
        margin-bottom: 2px;
    }
</style>