<template>
    <div>
        <a-breadcrumb v-if="false">
            <a-breadcrumb-item>Locations</a-breadcrumb-item>
        </a-breadcrumb>
        <a-table v-if="!is_mobile" style="margin-top: 10px;" :columns="columns" :data-source="store_list" :pagination="false">
            <template #bodyCell="{ column, record, text }">
                <template v-if="column.key === 'logo'">
                    <img :src="text" style="width: 50px;" />
                </template>
                <template v-else-if="column.key === 'action'">
                    <span>
                        <a href="javascript:;" @click="showEditLayer(record)">{{ this.$t('locations.edit_nickname') }}</a>
                    </span>
                </template>
                <template v-else>
                    <span>
                        {{ text }}
                    </span>
                </template>
            </template>
        </a-table>
        <div v-else>
            <a-card style="width: 100%; margin-top: 5px;" v-for="item in store_list" v-bind:key="item.sid">
                <a-card-meta :title="item.nick_name" :description="item.street_address + ', ' + item.locality + ', ' + item.country">
                    <template #avatar>
                        <a-avatar :src="item.slogo" />
                    </template>
                </a-card-meta>
                <p style="padding: 10px 0 0 50px; margin-bottom: 0px;">{{ item.user_name }}</p>
            </a-card>
        </div>
        <a-modal :title="this.$t('locations.edit_nickname')" v-model:open="show_edit_layer" @ok="handleOk" :cancelText="this.$t('common.cancel')" :okText="this.$t('common.ok')">
            <div>{{ this.$t('locations.nickname') }}</div>
            <a-input v-model:value="edit_nickname" placeholder="Nickname" :max-length="30" />
        </a-modal>
     </div>
</template>

<script>
    import { mapActions, mapMutations } from 'vuex';

    import {
    } from '@ant-design/icons-vue';

    export default {
        name: 'LocationsPage',
        components: {
        },
        data() {
            return {
                chain_id: this.$store.state.app.chain_id,
                is_mobile: this.$store.state.app.is_mobile,
                store_list: [],
                columns: [
                    {
                        title: 'Logo',
                        dataIndex: 'slogo',
                        key: 'logo',
                    },
                    {
                        title: this.$t('locations.nickname'),
                        dataIndex: 'nick_name',
                        key: 'nick_name',
                    },
                    {
                        title: this.$t('locations.address'),
                        dataIndex: 'street_address',
                        key: 'address',
                    },
                    {
                        title: this.$t('locations.city'),
                        dataIndex: 'locality',
                        key: 'city',
                    },
                    {
                        title: this.$t('locations.country'),
                        dataIndex: 'country',
                        key: 'country',
                    },
                    {
                        title: this.$t('common.phone'),
                        dataIndex: 'stel',
                        key: 'phone',
                    },
                    {
                        title: this.$t('common.email'),
                        dataIndex: 'user_name',
                        key: 'email',
                    },
                    {
                        title: this.$t('common.action'),
                        key: 'action',
                    },
                ],
                show_edit_layer: false,
                edit_nickname: '',
                edit_sid: '',
            };
        },
        created() {
            this.do_get_store_list();
        },
        computed: {},
        methods: {
            ...mapActions({
                getStoreList: 'app/getStoreList',
                editStoreName: 'app/editStoreName',
            }),
            ...mapMutations({
                setLoading: 'app/APP_SET_LOADING'
            }),
            async do_get_store_list() {
                let store_list = await this.getStoreList({
                    chain_id: this.chain_id,
                    page: 1,
                    size: 0
                });

                this.store_list = store_list;
            },
            showEditLayer(data) {
                this.show_edit_layer = true;

                this.edit_nickname = data.nick_name;
                this.edit_sid = data.sid;
            },
            async handleOk() {
                if (!this.edit_nickname) {
                    this.$notification['warning']({
                        message: 'Please input nickname!'
                    });
                    return;
                }

                if (this.edit_nickname.length < 2 || this.edit_nickname.length > 30) {
                    this.$notification['warning']({
                        message: 'The nickname length is between 2-30.'
                    });

                    return;
                }

                this.setLoading({
                    show_loading: true
                });

                let res = await this.editStoreName({
                    chain_id: this.chain_id,
                    sid: this.edit_sid,
                    nick_name: this.edit_nickname
                });

                if (res && res.code == 100000) {
                    this.$message.success('Successfully updated');
                    this.do_get_store_list();
                } else {
                    this.$notification['warning']({
                        message: res.msg
                    });
                }

                this.setLoading({
                    show_loading: false
                });

                this.edit_sid = '';
                this.edit_nickname = '';
                this.show_edit_layer = false;
            }
        }
    };
</script>

<style scoped>
</style>
