<template>
    <div class="insight_reserve">
        <a-row style="background-color: #fff;">
            <a-col :md="{ span: 18 }" :xs="{ span: 24 }">
                <a-menu v-model:selectedKeys="current" mode="horizontal" :items="items" />
            </a-col>
            <a-col :md="{ span: 6 }" :xs="{ span: 24 }" style="padding-top: 6px; padding-right: 5px;" v-if="store_options.length > 0">
                <a-select
                    v-model:value="sid"
                    :options="store_options"
                    style="width: 300px"
                    @change="handleChangeStore"
                >
                </a-select>
            </a-col>
        </a-row>
        <a-row style="margin: 10px 0;">
            <a-col :span="24" style="padding-right: 0px; height: auto;">
                <a-card :title="$t('insight.dashboard')" :bordered="false" style="width: 100%; height: 100%;">
                    <a-row :gutter="16">
                        <a-col :span="is_mobile ? 12 : 8" v-for="(item, index) in dashboard_cards" v-bind:key="item.text" :style="{marginTop: index > 1 ? '5px': '0px'}">
                            <a-card :bodyStyle="{'padding-left': '10px'}" style="width: 100%;background-color: #eee;">
                                <a-card-meta :title="mapper['dashboard'][item.type].text" :description="(mapper['dashboard'][item.type].sub_text == 'diners' || mapper['dashboard'][item.type].sub_text == '%') ? '(' + item.sub_value + ' ' + mapper['dashboard'][item.type].sub_text + ')' : mapper['dashboard'][item.type].sub_text">
                                    <template #avatar>
                                        <StopOutlined style="font-size: 28px;" v-if="mapper['dashboard'][item.type].icon == 'stop' && !is_mobile"/>
                                        <ContactsOutlined style="font-size: 28px;" v-if="mapper['dashboard'][item.type].icon == 'contacts' && !is_mobile" />
                                        <ScheduleOutlined style="font-size: 28px;" v-if="mapper['dashboard'][item.type].icon == 'schedule' && !is_mobile" />
                                        <h3 style="display: inline-block;font-size: 33px; margin-left: 5px;">{{ item.value }}</h3>
                                    </template>
                                </a-card-meta>
                            </a-card>
                        </a-col>
                    </a-row>
                </a-card>
            </a-col>
        </a-row>
        <a-row style="margin: 10px 0;">
            <a-col :span="24" style="padding-right: 5px; height: auto;">
                <a-card :title="$t('insight.number_of_reservations')" :bordered="false" style="width: 100%; height: 100%;">
                    <template #extra v-if="!is_mobile">
                        <a-range-picker @change="onDateChange('number')" v-model:value="number_date" :format="dateFormat"></a-range-picker>
                        <a-select v-model:value="number_graph_type" style="width: 120px" @change="handleNumberTypeChange('number', $event)">
                            <a-select-option value="daily">
                                {{ $t('insight.daily') }}
                            </a-select-option>
                            <a-select-option value="week">
                                {{ $t('insight.weekly') }}
                            </a-select-option>
                            <a-select-option value="month">
                                {{ $t('insight.monthly') }}
                            </a-select-option>
                            <a-select-option value="year">
                                {{ $t('insight.yearly') }}
                            </a-select-option>
                        </a-select>
                    </template>
                    <div v-if="is_mobile">
                        <a-range-picker @change="onDateChange('number')" v-model:value="number_date" :format="dateFormat"></a-range-picker>
                        <a-select v-model:value="number_graph_type" style="width: 120px" @change="handleNumberTypeChange('number', $event)">
                            <a-select-option value="daily">
                                {{ $t('insight.daily') }}
                            </a-select-option>
                            <a-select-option value="week">
                                {{ $t('insight.weekly') }}
                            </a-select-option>
                            <a-select-option value="month">
                                {{ $t('insight.monthly') }}
                            </a-select-option>
                            <a-select-option value="year">
                                {{ $t('insight.yearly') }}
                            </a-select-option>
                        </a-select>
                    </div>
                    <a-row :gutter="is_mobile ? 2 : 16" style="margin-top: 5px;">
                        <a-col :span="is_mobile ? 0 : 2">
                        </a-col>
                        <a-col :span="is_mobile ? 12 : 5">
                            <div class="insight_select_card" @click="changeSelectCard('number', 'reserve_count')" :class="{current: number_select_card == 'reserve_count'}">
                                <h3>{{ $t('insight.total_reservations') }}</h3>
                                <h2>{{ number_dashboard_cards.length > 0 && number_dashboard_cards[0].value || 0 }}</h2>
                                <h3>{{ number_dashboard_cards.length > 0 && number_dashboard_cards[0].sub_value || 0 }} {{ $t('insight.diners') }}</h3>
                            </div>
                        </a-col>
                        <a-col :span="is_mobile ? 12 : 5">
                            <div class="insight_select_card" @click="changeSelectCard('number', 'seated')" :class="{current: number_select_card == 'seated'}">
                                <h3>{{ $t('insight.seated_rez_number') }}</h3>
                                <h2>
                                    <span>{{ number_dashboard_cards.length > 0 && number_dashboard_cards[1].value || 0 }}</span>
                                    <span class="sub_text">
                                        <span>{{ number_dashboard_cards.length > 0 && number_dashboard_cards[1].percent || 0.0 }}%</span>
                                        <span>{{ $t('insight.attendance')}}</span>
                                    </span>
                                </h2>
                                <h3>{{ number_dashboard_cards.length > 0 && number_dashboard_cards[1].sub_value || 0 }} {{ $t('insight.diners') }}</h3>
                            </div>
                        </a-col>
                        <a-col :span="is_mobile ? 12 : 5">
                            <div class="insight_select_card" @click="changeSelectCard('number', 'cancelled_by_admin')" :class="{current: number_select_card == 'cancelled_by_admin'}">
                                <h3>{{ $t('insight.cancelled_by_restaurant') }}</h3>
                                <h2>
                                    <span>{{ number_dashboard_cards.length > 0 && number_dashboard_cards[2].value || 0 }}</span>
                                    <span class="sub_text">
                                        <span>{{ number_dashboard_cards.length > 0 && number_dashboard_cards[2].percent || 0.0 }}%</span>
                                        <span>&nbsp;</span>
                                    </span>
                                </h2>
                                <h3>{{ number_dashboard_cards.length > 0 && number_dashboard_cards[2].sub_value || 0 }} {{ $t('insight.diners') }}</h3>
                            </div>
                        </a-col>
                        <a-col :span="is_mobile ? 12 : 5">
                            <div class="insight_select_card" @click="changeSelectCard('number', 'cancelled_by_guest')" :class="{current: number_select_card == 'cancelled_by_guest'}">
                                <h3>{{ $t('insight.cancelled_by_guests') }}</h3>
                                <h2>
                                    <span>{{ number_dashboard_cards.length > 0 && number_dashboard_cards[3].value || 0 }}</span>
                                    <span class="sub_text">
                                        <span>{{ number_dashboard_cards.length > 0 && number_dashboard_cards[3].percent || 0.0 }}%</span>
                                        <span>&nbsp;</span>
                                    </span>
                                </h2>
                                <h3>{{ number_dashboard_cards.length > 0 && number_dashboard_cards[3].sub_value || 0 }} {{ $t('insight.diners') }}</h3>
                            </div>
                        </a-col>
                    </a-row>
                    <div style="margin-top: 20px; height: 300px;" class="chart" id="number_date_graph_chart"></div>
                    <div style="margin-top: 20px; height: 300px;" v-show="number_graph_type === 'daily'" class="chart" id="number_day_graph_chart"></div>
                </a-card>
            </a-col>
        </a-row>
        <a-row style="margin: 10px 0;">
            <a-col :span="24" style="padding-right: 5px; height: auto;">
                <a-card :title="$t('insight.reservation_source')" :loading="source_loading" :bordered="false" style="width: 100%; height: 100%;">
                    <template #extra v-if="!is_mobile">
                        <a-range-picker @change="onDateChange('source')" v-model:value="source_date" :format="dateFormat"></a-range-picker>
                        <a-select v-model:value="source_graph_type" style="width: 120px" @change="handleNumberTypeChange('source', $event)">
                            <a-select-option value="daily">
                                {{ $t('insight.daily') }}
                            </a-select-option>
                            <a-select-option value="week">
                                {{ $t('insight.weekly') }}
                            </a-select-option>
                            <a-select-option value="month">
                                {{ $t('insight.monthly') }}
                            </a-select-option>
                            <a-select-option value="year">
                                {{ $t('insight.yearly') }}
                            </a-select-option>
                        </a-select>
                    </template>
                    <div v-if="is_mobile">
                        <a-range-picker @change="onDateChange('source')" v-model:value="source_date" :format="dateFormat"></a-range-picker>
                        <a-select v-model:value="source_graph_type" style="width: 120px" @change="handleNumberTypeChange('source', $event)">
                            <a-select-option value="daily">
                                {{ $t('insight.daily') }}
                            </a-select-option>
                            <a-select-option value="week">
                                {{ $t('insight.weekly') }}
                            </a-select-option>
                            <a-select-option value="month">
                                {{ $t('insight.monthly') }}
                            </a-select-option>
                            <a-select-option value="year">
                                {{ $t('insight.yearly') }}
                            </a-select-option>
                        </a-select>
                    </div>
                    <a-row :gutter="is_mobile ? 2 : 16" style="margin-top: 5px;">
                        <a-col :span="is_mobile ? 0 : 2">
                        </a-col>
                        <a-col :span="is_mobile ? 8 : 4">
                            <div class="insight_select_card" @click="changeSelectCard('source', 'online')" :class="{current: source_select_card == 'online'}">
                                <h3>{{ $t('insight.online_rez') }}</h3>
                                <h2>
                                    <span>{{ source_dashboard_cards.length > 0 && source_dashboard_cards[0].value || 0 }}</span>
                                    <span class="sub_text">
                                        <span>{{ source_dashboard_cards.length > 0 && source_dashboard_cards[0].percent || '0.0' }}%</span>
                                        <span>&nbsp;</span>
                                    </span>
                                </h2>
                                <h3>{{ source_dashboard_cards.length > 0 && source_dashboard_cards[0].sub_value || 0 }} {{ $t('insight.diners') }}</h3>
                            </div>
                        </a-col>
                        <a-col :span="is_mobile ? 8 : 4">
                            <div class="insight_select_card" @click="changeSelectCard('source', 'phone_call')" :class="{current: source_select_card == 'phone_call'}">
                                <h3>{{ $t('insight.via_phone') }}</h3>
                                <h2>
                                    <span>{{ source_dashboard_cards.length > 0 && source_dashboard_cards[1].value || 0 }}</span>
                                    <span class="sub_text">
                                        <span>{{ source_dashboard_cards.length > 0 && source_dashboard_cards[1].percent || '0.0' }}%</span>
                                        <span>&nbsp;</span>
                                    </span>
                                </h2>
                                <h3>{{ source_dashboard_cards.length > 0 && source_dashboard_cards[1].sub_value || 0 }} {{ $t('insight.diners') }}</h3>
                            </div>
                        </a-col>
                        <a-col :span="is_mobile ? 8 : 4">
                            <div class="insight_select_card" @click="changeSelectCard('source', 'google')" :class="{current: source_select_card == 'google'}">
                                <h3>{{ $t('insight.via_google') }}</h3>
                                <h2>
                                    <span>{{ source_dashboard_cards.length > 0 && source_dashboard_cards[2].value || 0 }}</span>
                                    <span class="sub_text">
                                        <span>{{ source_dashboard_cards.length > 0 && source_dashboard_cards[2].percent || '0.0' }}%</span>
                                        <span>&nbsp;</span>
                                    </span>
                                </h2>
                                <h3>{{ source_dashboard_cards.length > 0 && source_dashboard_cards[2].sub_value || 0 }} {{ $t('insight.diners') }}</h3>
                            </div>
                        </a-col>
                    </a-row>
                    <div class="chart" style="margin-top: 20px; height: 300px;" id="source_date_graph_chart"></div>
                </a-card>
            </a-col>
        </a-row>
        <a-row style="margin: 10px 0;">
            <a-col :span="24" style="padding-right: 5px; height: auto;">
                <a-card :title="$t('insight.reservation_time')" :bordered="false" style="width: 100%; height: 100%;">
                    <template #extra v-if="!is_mobile">
                        <a-range-picker @change="onDateChange('reserve_time')" v-model:value="reserve_time_date" :format="dateFormat"></a-range-picker>
                        <a-select v-if="false" v-model:value="reserve_time_graph_type" style="width: 120px" @change="handleNumberTypeChange('reserve_time', $event)">
                            <a-select-option value="daily">
                                {{ $t('insight.daily') }}
                            </a-select-option>
                            <a-select-option value="week">
                                {{ $t('insight.weekly') }}
                            </a-select-option>
                            <a-select-option value="month">
                                {{ $t('insight.monthly') }}
                            </a-select-option>
                            <a-select-option value="year">
                                {{ $t('insight.yearly') }}
                            </a-select-option>
                        </a-select>
                    </template>
                    <div v-if="is_mobile">
                        <a-range-picker @change="onDateChange('reserve_time')" v-model:value="reserve_time_date" :format="dateFormat"></a-range-picker>
                        <a-select v-if="false" v-model:value="reserve_time_graph_type" style="width: 120px" @change="handleNumberTypeChange('reserve_time', $event)">
                            <a-select-option value="daily">
                                {{ $t('insight.daily') }}
                            </a-select-option>
                            <a-select-option value="week">
                                {{ $t('insight.weekly') }}
                            </a-select-option>
                            <a-select-option value="month">
                                {{ $t('insight.monthly') }}
                            </a-select-option>
                            <a-select-option value="year">
                                {{ $t('insight.yearly') }}
                            </a-select-option>
                        </a-select>
                    </div>
                    <a-row :gutter="is_mobile ? 2 : 16" style="margin-top: 5px;">
                        <a-col :span="is_mobile ? 0 : 2">
                        </a-col>
                        <a-col :span="is_mobile ? 16 : 4">
                            <div class="insight_select_card current">
                                <h3>{{ $t('insight.average_in_advance') }}</h3>
                                <h2>{{reserve_time_dashboard_cards.length > 0 && reserve_time_dashboard_cards[0].value || '0.0'}} {{ $t('insight.day' )}}(s)</h2>
                            </div>
                        </a-col>
                        <a-col :span="is_mobile ? 8 : 4">
                            <div class="insight_select_card current">
                                <h3>{{ $t('insight.mode') }}</h3>
                                <h2>{{reserve_time_dashboard_cards.length > 0 && reserve_time_dashboard_cards[1].value || '0.0'}} {{ $t('insight.day' )}}</h2>
                            </div>
                        </a-col>
                    </a-row>
                    <div class="chart" style="height: 300px;" id="reserve_time_graph_chart"></div>
                </a-card>
            </a-col>
        </a-row>
        <a-row style="margin: 10px 0;">
            <a-col :span="24" style="padding-right: 5px; height: auto;">
                <a-card :title="$t('insight.party_size')" :bordered="false" style="width: 100%; height: 100%;">
                    <template #extra v-if="!is_mobile">
                        <a-range-picker @change="onDateChange('party_size')" v-model:value="party_size_date" :format="dateFormat"></a-range-picker>
                        <a-select v-if="false" v-model:value="party_size_graph_type" style="width: 120px" @change="handleNumberTypeChange('party_size', $event)">
                            <a-select-option value="daily">
                                {{ $t('insight.daily') }}
                            </a-select-option>
                            <a-select-option value="week">
                                {{ $t('insight.weekly') }}
                            </a-select-option>
                            <a-select-option value="month">
                                {{ $t('insight.monthly') }}
                            </a-select-option>
                            <a-select-option value="year">
                                {{ $t('insight.yearly') }}
                            </a-select-option>
                        </a-select>
                    </template>
                    <div v-if="is_mobile">
                        <a-range-picker @change="onDateChange('party_size')" v-model:value="party_size_date" :format="dateFormat"></a-range-picker>
                        <a-select v-if="false" v-model:value="party_size_graph_type" style="width: 120px" @change="handleNumberTypeChange('party_size', $event)">
                            <a-select-option value="daily">
                                {{ $t('insight.daily') }}
                            </a-select-option>
                            <a-select-option value="week">
                                {{ $t('insight.weekly') }}
                            </a-select-option>
                            <a-select-option value="month">
                                {{ $t('insight.monthly') }}
                            </a-select-option>
                            <a-select-option value="year">
                                {{ $t('insight.yearly') }}
                            </a-select-option>
                        </a-select>
                    </div>
                    <a-row :gutter="is_mobile ? 2 : 16" style="margin-top: 5px;">
                        <a-col :span="is_mobile ? 0 : 2">
                        </a-col>
                        <a-col :span="is_mobile ? 20 : 6">
                            <div class="insight_select_card current">
                                <h3>{{ $t('insight.most_popular_party_type') }}</h3>
                                <h2>{{ party_size_dashboard_cards.length > 0 && party_size_dashboard_cards[0].value || 0}} {{ $t('insight.guests') }}</h2>
                                <h2>{{ party_size_dashboard_cards.length > 0 && party_size_dashboard_cards[0].sub_value || 0}} {{ $t('insight.reserve') }}, {{ $t('insight.take_up') }} {{ party_size_dashboard_cards.length > 0 && party_size_dashboard_cards[0].percent || '0.0'}}%</h2>
                            </div>
                        </a-col>
                    </a-row>
                    <div class="chart" style="margin-top: 20px; height: 300px;" id="party_size_graph_chart"></div>
                </a-card>
            </a-col>
        </a-row>
    </div>
</template>
<script>
    import { mapState, mapActions, mapGetters, mapMutations } from "vuex";
    import * as echarts from 'echarts';
    import moment from 'moment-timezone';
    import { h } from 'vue';
    import dayjs from 'dayjs';

    import {
        MailOutlined,
        TeamOutlined,
        GlobalOutlined,
        ClockCircleOutlined,
        ScheduleOutlined,
        ContactsOutlined,
        StopOutlined,
    } from '@ant-design/icons-vue';

    export default {
        name: "insight_reserve",
        components: {
            ScheduleOutlined,
            ContactsOutlined,
            StopOutlined,
        },
        data() {
            return {
                items: [
                {
                        key: 'summary',
                        icon: () => h(GlobalOutlined),
                        label: this.$t('insight.summary'),
                        title: 'Summary',
                    },
                    {
                        key: 'waitlist',
                        icon: () => h(TeamOutlined),
                        label: this.$t('insight.waitlist'),
                        title: 'Waitlist',
                    },
                    {
                        key: 'reserve',
                        icon: () => h(ClockCircleOutlined),
                        label: this.$t('insight.reserve'),
                        title: 'Reserve',
                    },
                    {
                        key: 'notification',
                        icon: () => h(MailOutlined),
                        label: this.$t('insight.notification'),
                        title: 'Notification',
                    },
                ],
                sid: this.$store.state.app.select_sid,
                store_options: [],
                chain_id: this.$store.state.app.chain_id,
                cluster: this.$store.state.app.cluster,
                timezone: this.$store.state.app.timezone,
                is_mobile: this.$store.state.app.is_mobile,
                current: ['reserve'],
                reserveNumberDateGraphChart: null,
                reserveNumberDayGraphChart: null,
                reserveTimeGraphChart: null,
                reservePartySizeGraphChart: null,

                number_graph_type: 'daily',
                source_graph_type: 'daily',
                reserve_time_graph_type: 'daily',
                party_size_graph_type: 'daily',

                dateFormat: 'YYYY-MM-DD',
                moment: moment,

                dashboard_cards: [],
                dashboad_loading: false,

                number_graph_data: null,
                number_graph_day_data: null,
                number_dashboard_cards: [],
                number_graph_loading: false,

                source_graph_data: null,
                source_dashboard_cards: [],
                source_graph_loading: false,

                reserve_time_dashboard_cards: [],
                reserve_time_graph_data: null,

                party_size_dashboard_cards: [],
                party_size_graph_data: null,

                number_select_card: 'reserve_count',
                source_select_card: 'online',

                number_date: [],
                source_date: [],
                reserve_time_date: [],
                party_size_date: [],

                mapper: {
                    dashboard: {
                        rez_online: {
                            text: this.$t('insight.today_online_rez'),
                            sub_text: this.$t('insight.diners'),
                            icon: 'schedule'
                        },
                        rez_total: {
                            text: this.$t('insight.today_rez'),
                            sub_text: '%',
                            icon: 'contacts'
                        },
                        rez_seated: {
                            text: this.$t('insight.total_seated_rez'),
                            sub_text: this.$t('insight.diners'),
                            icon: 'schedule'
                        },
                        rez_cancelled_admin: {
                            text: this.$t('insight.rez_cancelled'),
                            sub_text: this.$t('insight.by_restaurant'),
                            icon: 'stop'
                        },
                        rez_cancelled_guest: {
                            text: this.$t('insight.rez_cancelled'),
                            sub_text: this.$t('insight.by_guests'),
                            icon: 'stop'
                        },
                    }
                }
            }
        },
        async beforeMount() {
            let sdate = dayjs(moment().subtract(30, 'days').format(this.dateFormat), this.dateFormat)
            let edate = dayjs(moment().subtract(0, 'days').format(this.dateFormat), this.dateFormat)

            this.number_date = [sdate, edate];
            this.source_date = [sdate, edate];
            this.reserve_time_date = [sdate, edate];
            this.party_size_date = [sdate, edate];
        },
        async mounted() {
            this.do_get_store_list();
        },
        watch: {
            current() {
                if (this.current == 'summary') {
                    this.$router.push({
                        path: '/insight/home'
                    });
                } else if (this.current == 'waitlist') {
                    this.$router.push({
                        path: '/insight/waitlist'
                    });
                } else if (this.current == 'notification') {
                    this.$router.push({
                        path: '/insight/notification'
                    })
                }
            },
        },
        computed: {
            ...mapGetters({
            }),
            ...mapState({
            }),
            numberDateGraphOption() {
                let title = this.$t('insight.total_reservation_number');

                if (this.number_select_card == 'seated') {
                    title = this.$t('insight.seated_reservation_number');
                } else if (this.number_select_card == 'cancelled_by_admin') {
                    title = this.$t('insight.cancelled_by_restaurant');
                } else if (this.number_select_card == 'cancelled_by_guest') {
                    title = this.$t('insight.cancelled_by_guests');
                }

                let option = {
                    title: {
                        show: true,
                        text: title,
                        left: 'center',
                        textStyle: {
                            color: '#333',
                            fontSize: 16,
                        },
                    },
                    xAxis: {
                        data: []
                    },
                    yAxis: {
                    },
                    tooltip: {
                        show: true,
                        trigger: 'item'
                    },
                    series: [
                        {
                            type: 'bar',
                            data: []
                        }
                    ]
                }

                if (this.number_graph_data) {
                    option.xAxis.data = this.number_graph_data.label;
                    option.series[0].data = this.number_graph_data.data;
                }

                return option;
            },
            numberDayGraphOption() {
                let title = this.$t('insight.total_reservation_number') + this.$t('insight.by_days');

                if (this.number_select_card == 'seated') {
                    title = this.$t('insight.seated_reservation_number') + this.$t('insight.by_days');
                } else if (this.number_select_card == 'cancelled_by_admin') {
                    title = this.$t('insight.cancelled_by_restaurant') + this.$t('insight.by_days');
                } else if (this.number_select_card == 'cancelled_by_guest') {
                    title = this.$t('insight.cancelled_by_guests') + this.$t('insight.by_days');
                }

                let option = {
                    title: {
                        show: true,
                        text: title,
                        left: 'center',
                        textStyle: {
                            color: '#333',
                            fontSize: 16,
                        },
                    },
                    xAxis: {
                        data: []
                    },
                    yAxis: {},
                    tooltip: {
                        show: true,
                        trigger: 'item'
                    },
                    series: [
                        {
                            type: 'bar',
                            data: []
                        }
                    ]
                }

                if (this.number_graph_day_data) {
                    option.xAxis.data = this.number_graph_day_data.label;
                    option.series[0].data = this.number_graph_day_data.data;
                }

                return option;
            },
            sourceDateGraphOption() {
                let title = this.$t('insight.online_rez');

                if (this.source_select_card == 'phone_call') {
                    title = this.$t('insight.via_phone');
                } else if (this.source_select_card == 'google') {
                    title = this.$t('insight.via_google');
                }

                let option = {
                    title: {
                        show: true,
                        text: title,
                        left: 'center',
                        textStyle: {
                            color: '#333',
                            fontSize: 16,
                        },
                    },
                    xAxis: {
                        data: []
                    },
                    yAxis: {},
                    tooltip: {
                        show: true,
                        trigger: 'item'
                    },
                    series: [
                        {
                            type: 'bar',
                            data: []
                        }
                    ]
                }

                if (this.source_graph_data) {
                    option.xAxis.data = this.source_graph_data.label;
                    option.series[0].data = this.source_graph_data.data;
                }

                return option;
            },
            reserveTimeGraphOption() {
                let option = {
                    title: {
                        show: false,
                        text: 'How long in advance did customer reserve tables?',
                        left: 'center',
                        textStyle: {
                            color: '#333',
                            fontSize: 14,
                        },
                    },
                    xAxis: {
                        data: [],
                        nameRotate: 30
                    },
                    yAxis: {},
                    tooltip: {
                        show: true,
                        trigger: 'item'
                    },
                    series: [
                        {
                            type: 'bar',
                            data: []
                        }
                    ]
                }

                if (this.reserve_time_graph_data) {
                    option.xAxis.data = this.reserve_time_graph_data.label;
                    option.series[0].data = this.reserve_time_graph_data.data;
                }

                return option;
            },
            partySizeGraphOption() {
                let option = {
                    title: {
                        show: true,
                        text: this.$t('insight.reservation_of_diffenrent_party_sizes'),
                        left: 'center',
                        textStyle: {
                            color: '#333',
                            fontSize: 16,
                        },
                    },
                    xAxis: {
                        data: [],
                        name: this.$t('insight.guest_number_of_party'),
                        nameLocation: 'middle',
                        nameTextStyle: {
                            padding: [10, 0, 0, 0]
                        }
                    },
                    yAxis: {
                        name: this.$t('insight.reservation_amount'),
                        nameLocation: 'middle',
                        nameTextStyle: {
                            padding: [0, 0, 10, 0]
                        }
                    },
                    tooltip: {
                        show: true,
                        trigger: 'item',
                        formatter: '{b} Guests: {c}'
                    },
                    series: [
                        {
                            type: 'bar',
                            data: []
                        }
                    ]
                }

                if (this.party_size_graph_data) {
                    option.xAxis.data = this.party_size_graph_data.label;
                    option.series[0].data = this.party_size_graph_data.data;
                }

                return option;
            }
        },
        methods: {
            ...mapActions({
                get_reserve_dashboard: "insight/get_reserve_dashboard",
                get_reserve_graph: 'insight/get_reserve_graph',
                get_reserve_source: 'insight/get_reserve_source',
                get_reserve_time: 'insight/get_reserve_time',
                get_reserve_party: 'insight/get_reserve_party',
                getStoreList: 'app/getStoreList',
            }),
            ...mapMutations({
                setSelectSid: 'app/APP_SET_SELECT_SID'
            }),
            handleChangeStore() {
                this.setSelectSid(this.sid);
                this.request_data();
            },
            async do_get_store_list() {
                let store_list = await this.getStoreList({
                    chain_id: this.chain_id,
                    page: 1,
                    size: 0
                });

                this.store_options = store_list.map(item => {
                    return {
                        value: item.sid,
                        label: item.nick_name,
                    };
                });

                if (!this.sid) {
                    this.setSelectSid(this.store_options[0] && this.store_options[0].value || '');
                    this.sid = this.store_options[0] && this.store_options[0].value || '';
                }

                this.request_data();
            },
            async request_data() {
                this.request_dashboard();
                this.request_number_dashboard();
                this.request_reserve_number_graph();
                this.request_source_dashboard();
                this.request_reserve_source_graph();
                this.request_reserve_time_data();
                this.request_party_size_data();
            },
            async request_dashboard() {
                this.dashboad_loading = true;
                let dashboard_data = await this.get_reserve_dashboard({
                    sid: this.sid,
                    sdate: '',
                    edate: '',
                    cluster: this.cluster
                });

                if (dashboard_data.code === 100000) {
                    this.dashboard_cards = dashboard_data.data.cards;
                }

                this.dashboad_loading = false;
            },
            async request_number_dashboard() {
                let dashboard_data = await this.get_reserve_dashboard({
                    sid: this.sid,
                    sdate: dayjs(this.number_date[0]).format(this.dateFormat),
                    edate: dayjs(this.number_date[1]).format(this.dateFormat),
                    cluster: this.cluster
                });

                if (dashboard_data.code === 100000) {
                    this.number_dashboard_cards = dashboard_data.data.cards;
                }
            },
            async request_reserve_number_graph() {
                this.number_graph_loading = true;
                let number_graph_data = await this.get_reserve_graph({
                    sid: this.sid,
                    cluster: this.cluster,
                    type: this.number_graph_type,
                    source: this.number_select_card == 'reserve_count' ? '' : this.number_select_card,
                    sdate: dayjs(this.number_date[0]).format(this.dateFormat),
                    edate: dayjs(this.number_date[1]).format(this.dateFormat),
                });

                if (number_graph_data.code === 100000) {
                    this.number_graph_data = number_graph_data.data;

                    if (this.number_graph_type == 'daily') {
                        let { data, label_ori } = this.number_graph_data;

                        let new_data = [];
                        let new_label = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
                        let new_dict = {};

                        for (let i = 0; i < label_ori.length; i++) {
                            let day = moment.tz(label_ori[i], this.timezone).day();

                            if (!new_dict[new_label[day]]) {
                                new_dict[new_label[day]] = 0;
                            }

                            new_dict[new_label[day]] += data[i];
                        }

                        for (let day in new_dict) {
                            let index = new_label.indexOf(day);

                            new_data[index] = new_dict[day];
                        }

                        this.number_graph_day_data = {
                            data: new_data,
                            label: new_label
                        };
                    } 
                    
                    this.render_number_date_graph();
                    this.render_number_day_graph();
                }

                this.number_graph_loading = false;
            },
            async request_reserve_source_graph() {
                this.source_graph_loading = true;
                let source_graph_data = await this.get_reserve_graph({
                    sid: this.sid,
                    cluster: this.cluster,
                    type: this.source_graph_type,
                    source: this.source_select_card,
                    sdate: dayjs(this.source_date[0]).format(this.dateFormat),
                    edate: dayjs(this.source_date[1]).format(this.dateFormat),
                });

                if (source_graph_data.code === 100000) {
                    this.source_graph_data = source_graph_data.data;
                    this.render_source_date_graph();
                }

                this.source_graph_loading = false;
            },
            async request_source_dashboard() {
                let source_dashboard_data = await this.get_reserve_source({
                    sid: this.sid,
                    sdate: dayjs(this.source_date[0]).format(this.dateFormat),
                    edate: dayjs(this.source_date[1]).format(this.dateFormat),
                    cluster: this.cluster
                });

                if (source_dashboard_data.code === 100000) {
                    this.source_dashboard_cards = source_dashboard_data.data.cards;
                }
            },
            async request_reserve_time_data() {
                let reserve_time_data = await this.get_reserve_time({
                    sid: this.sid,
                    sdate: dayjs(this.reserve_time_date[0]).format(this.dateFormat),
                    edate: dayjs(this.reserve_time_date[1]).format(this.dateFormat),
                    cluster: this.cluster
                });

                if (reserve_time_data.code === 100000) {
                    this.reserve_time_dashboard_cards = reserve_time_data.data.cards;
                    this.reserve_time_graph_data = {
                        data: reserve_time_data.data.data,
                        label: reserve_time_data.data.label
                    };

                    this.render_reserve_time_graph();
                }
            },
            async request_party_size_data() {
                let party_size_data = await this.get_reserve_party({
                    sid: this.sid,
                    sdate: dayjs(this.party_size_date[0]).format(this.dateFormat),
                    edate: dayjs(this.party_size_date[1]).format(this.dateFormat),
                    cluster: this.cluster
                });

                if (party_size_data.code === 100000) {
                    this.party_size_dashboard_cards = party_size_data.data.cards;
                    this.party_size_graph_data = {
                        data: party_size_data.data.data,
                        label: party_size_data.data.label
                    };

                    this.render_party_size_graph();
                }
            },
            render_number_date_graph() {
                var chartDom = document.getElementById('number_date_graph_chart');
                this.waitlistNumberDateGraphChart = echarts.init(chartDom);
                
                this.numberDateGraphOption && this.waitlistNumberDateGraphChart.setOption(this.numberDateGraphOption);
            },
            render_number_day_graph() {
                var chartDom = document.getElementById('number_day_graph_chart');
                this.waitlistNumberDateGraphChart = echarts.init(chartDom);
                
                this.numberDayGraphOption && this.waitlistNumberDateGraphChart.setOption(this.numberDayGraphOption);
            },
            render_source_date_graph() {
                var chartDom = document.getElementById('source_date_graph_chart');
                this.waitlistSourceDayGraphChart = echarts.init(chartDom);
                
                this.sourceDateGraphOption && this.waitlistSourceDayGraphChart.setOption(this.sourceDateGraphOption);
            },
            render_reserve_time_graph() {
                var chartDom = document.getElementById('reserve_time_graph_chart');
                this.reserveTimeGraphChart = echarts.init(chartDom);
                
                this.reserveTimeGraphOption && this.reserveTimeGraphChart.setOption(this.reserveTimeGraphOption);
            },
            render_party_size_graph() {
                var chartDom = document.getElementById('party_size_graph_chart');
                this.waitlistPartySizeGraphChart = echarts.init(chartDom);
                
                this.partySizeGraphOption && this.waitlistPartySizeGraphChart.setOption(this.partySizeGraphOption);
            },
            changeSelectCard(type, value) {
                this[type + '_select_card'] = value;

                if (type == 'number') {
                    this.request_reserve_number_graph();
                } else if(type == 'source') {
                    this.request_reserve_source_graph();
                } else if(type == 'reserve_time') {
                    console.log('reserve_time');
                } else if(type == 'party_size'){
                    console.log('party_size');
                }
            },
            handleNumberTypeChange(type, value) {
                this[type + '_graph_type'] = value;

                if (type == 'number') {
                    this.request_reserve_number_graph();
                } else if(type == 'source') {
                    this.request_reserve_source_graph();
                } else if(type == 'reserve_time') {
                    console.log('reserve_time');
                } else if(type == 'party_size'){
                    console.log('party_size');
                }
            },
            onDateChange(type) {
                if (type == 'number') {
                    this.request_number_dashboard();
                    this.request_reserve_number_graph();
                } else if(type == 'source') {
                    this.request_source_dashboard();
                    this.request_reserve_source_graph();
                } else if(type == 'reserve_time') {
                    this.request_reserve_time_data();
                } else if(type == 'party_size'){
                    this.request_party_size_data();
                }
            },
        }
    }
</script>

<style>
    .insight_reserve {
        max-height: 97vh;
        overflow: auto;
    }
    .insight_reserve .ant-card-body {
        padding-left: 30px;
    }
    .insight_reserve p {
        margin-bottom: 2px;
    }
    .insight_select_card {
        border: 1px solid #000;
        border-radius: 5px;
        padding: 5px 10px 5px 10px;
        cursor: pointer;
    }
    .insight_select_card.current {
        border: 1px solid #fe7f03;
    }

    .insight_select_card .sub_text {
        display: inline-block;
        font-size: 12px;
    }

    .insight_select_card .sub_text {
        display: inline-block;
        font-size: 10px;
    }

    .insight_select_card .sub_text span {
        display: block;
    }
</style>