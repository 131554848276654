<template>
    <div>
        <a-breadcrumb v-if="false">
            <a-breadcrumb-item>Survey</a-breadcrumb-item>
        </a-breadcrumb>
        <div style="margin-top: 10px;">
            <a-date-picker :disabled-date="disabledDate" @change="onDateChange" :inputReadOnly="true" :format="dateFormat" style="width: 150px; margin-left: 10px;"  v-model:value="sdate" />
            <span>&nbsp;-&nbsp;</span>
            <a-date-picker :disabled-date="disabledDate" @change="onDateChange" :inputReadOnly="true" :format="dateFormat" style="width: 150px;" v-model:value="edate" />
            <a-button type="primary" style="margin-left: 10px;" @click="onDateChange">{{ $t('common.search') }}</a-button>
            <a-button type="default" style="margin-left: 10px;" :disabled="survey_list.length == 0"  @click="exportData">{{ $t('common.export') }}</a-button>
            <a-select
                v-if="store_options.length > 0"
                v-model:value="select_sid"
                :options="store_options"
                style="width: 200px; float: right;"
                @change="handleChangeStore"
            >
            </a-select>
        </div>
        <div v-if="!is_mobile">
            <a-table :loading="is_loading" style="margin-top: 10px;" :scroll="{ y: 450 }" :columns="survey_columns" :data-source="survey_list" :pagination="false">
                <template #bodyCell="{ column, text }">
                    <template v-if="column.key === 'logo'">
                        <img :src="text" style="width: 50px;" />
                    </template>
                    <template v-else-if="column.key === 'action'">
                        <span>
                        </span>
                    </template>
                    <template v-else>
                        <span>
                            {{ text }}
                        </span>
                    </template>
                </template>
            </a-table>
            <a-pagination style="padding: 10px 0; background-color: #fff;" :showSizeChanger="false" v-model:current="current" :total="total_count" show-less-items />
        </div>
        <div style="padding-bottom: 60px;" v-else>
            <a-card style="width: 100%; margin-top: 5px;" v-for="item in survey_list" v-bind:key="item.idx">
                <p>Date: {{ item.date + ' ' + item.time }}</p>
                <span style="display: block;" v-for="col_item in survey_columns" v-bind:key="col_item.key">
                    <span v-if="col_item.key != 'index' && col_item.key != 'date' && col_item.key != 'time'">{{ col_item.title + " : " + (item[col_item.key] || '') }}</span>
                </span>
            </a-card>
            <div v-if="is_loading" style="width: 100%; text-align: center; padding: 20px;">
                <a-spin></a-spin>
            </div>
            <a-button v-if="current < total_page" @click="doLoadMore" style="width: 100%; margin-top: 5px;">Load more</a-button>
        </div>
     </div>
</template>

<script>
    import { mapActions, mapMutations } from 'vuex';

    import {
    } from '@ant-design/icons-vue';
    import moment from 'moment-timezone';
    import dayjs from 'dayjs';

    export default {
        name: 'SurveyPage',
        components: {
        },
        data() {
            return {
                chain_id: this.$store.state.app.chain_id,
                select_sid: this.$store.state.app.select_sid,
                is_mobile: this.$store.state.app.is_mobile,
                store_options: [],

                disabledDate: (current) => {
                    return current && current > moment().subtract(0, 'days');
                },
                dateFormat: 'YYYY-MM-DD',
                sdate: dayjs(moment().subtract(30, 'days').format(this.dateFormat), this.dateFormat),
                edate: dayjs(moment().subtract(0, 'days').format(this.dateFormat), this.dateFormat),
                current: 1,
                total_count: 0,
                total_page: 0,
                survey_list: [],
                survey_setting: null,
                is_loading: false,
            };
        },
        created() {
            this.do_get_store_list();
        },
        computed: {
            survey_columns() {
                let columns = [
                    {
                        title: '#',
                        dataIndex: 'index',
                        key: 'index',
                        width: 50,
                        fixed: 'left'
                    },
                    {
                        title: this.$t('common.date'),
                        dataIndex: 'date',
                        key: 'date',
                        width: 120,
                        fixed: 'left'
                    },
                    {
                        title: this.$t('common.time'),
                        dataIndex: 'time',
                        key: 'time',
                        width: 100
                    }
                ];

                if (this.survey_setting) {
                    if (this.survey_setting.name_show) {
                        columns.push({
                            title: this.$t('common.name'),
                            dataIndex: 'name',
                            key: 'name',
                            width: 120
                        });
                    }

                    if (this.survey_setting.tel_show) {
                        columns.push({
                            title: this.$t('common.phone'),
                            dataIndex: 'tel',
                            key: 'tel',
                            width: 180
                        });
                    }

                    if (this.survey_setting.food_star_show) {
                        columns.push({
                            title: this.$t('survey.food_star'),
                            dataIndex: 'food_star',
                            key: 'food_star',
                            width: 120
                        });
                    }

                    if (this.survey_setting.service_star_show) {
                        columns.push({
                            title: this.$t('survey.service_star'),
                            dataIndex: 'service_star',
                            key: 'service_star',
                            width: 120
                        });
                    }

                    if (this.survey_setting.introduce_show) {
                        columns.push({
                            title: this.$t('survey.menu_star'),
                            dataIndex: 'have_desc',
                            key: 'have_desc',
                            width: 100
                        });
                    }

                    if (this.survey_setting.speed_show) {
                        columns.push({
                            title: this.$t('survey.speed_star'),
                            dataIndex: 'serve_speed',
                            key: 'serve_speed',
                            width: 120
                        });
                    }

                    if (this.survey_setting.first_time_show) {
                        columns.push({
                            title: this.$t('survey.is_first_time'),
                            dataIndex: 'first_time_text',
                            key: 'first_time_text',
                            width: 120
                        });
                    }

                    if (this.survey_setting.frequency_show) {
                        columns.push({
                            title: this.$t('survey.frequency'),
                            dataIndex: 'visit_frequency',
                            key: 'visit_frequency',
                            width: 120
                        });
                    }

                    if (this.survey_setting.know_method_show) {
                        columns.push({
                            title: this.$t('survey.known_method'),
                            dataIndex: 'known_method',
                            key: 'known_method',
                            width: 150
                        });
                    }

                    if (this.survey_setting.birthday_show) {
                        columns.push({
                            title: this.$t('common.birthday'),
                            dataIndex: 'birthday',
                            key: 'birthday',
                            width: 120
                        });
                    }

                    if (this.survey_setting.email_show) {
                        columns.push({
                            title: this.$t('common.email'),
                            dataIndex: 'email',
                            key: 'email',
                            width: 120
                        });
                    }

                    if (this.survey_setting.favorite_food_show) {
                        columns.push({
                            title: this.$t('survey.favorite_food'),
                            dataIndex: 'favorite_food',
                            key: 'favorite_food',
                            width: 150
                        });
                    }

                    if (this.survey_setting.suggestion_show) {
                        columns.push({
                            title: this.$t('survey.suggestion'),
                            dataIndex: 'suggestion',
                            key: 'suggestion',
                            width: 200
                        });
                    }

                    if (this.survey_setting.custom_question) {
                        for (let i = 0; i < this.survey_setting.custom_question.length; i++) {
                            let { q_name } = this.survey_setting.custom_question[i];

                            columns.push({
                                title: q_name,
                                dataIndex: 'custom_question_' + i,
                                key: 'custom_question_' + i,
                                width: 150
                            });
                        }
                    }

                    columns.push({
                        title: this.$t('survey.note'),
                        dataIndex: 'note',
                        key: 'note',
                        width: 150
                    });
                }

                return columns
            },
        },
        methods: {
            ...mapActions({
                getStoreList: 'app/getStoreList',
                getSurveyList: 'survey/getSurveyList',
                getSurveyData: 'survey/getSurveyData',
                getSurveySetting: 'survey/getSurveySetting',
                exportSurveyData: 'survey/exportSurveyData',
            }),
            ...mapMutations({
                setLoading: 'app/APP_SET_LOADING',
                setSelectSid: 'app/APP_SET_SELECT_SID'
            }),
            doLoadMore() {
                this.current += 1;
                this.do_get_survey_list();
            },
            handleChangeStore() {
                this.current = 1;
                this.do_get_survey_list();
                this.setSelectSid(this.select_sid);
            },
            async do_get_survey_setting() {
                let survey_setting = await this.getSurveySetting({
                    sid: this.select_sid,
                });

                if (survey_setting.code == 100000) {
                    this.survey_setting = survey_setting.data.info;
                }
            },
            async do_get_store_list() {
                let store_list = await this.getStoreList({
                    chain_id: this.chain_id,
                    page: 1,
                    size: 0
                });

                this.store_options = store_list.map(item => {
                    return {
                        value: item.sid,
                        label: item.nick_name,
                    };
                });

                if (!this.select_sid) {
                    this.setSelectSid(this.store_options[0] && this.store_options[0].value || '');
                    this.select_sid = this.store_options[0] && this.store_options[0].value || '';
                }

                await this.do_get_survey_setting();
                this.do_get_survey_list();
            },
            async do_get_survey_list() {
                this.is_loading = true;
                let sdate = dayjs(this.sdate).format(this.dateFormat);
                let edate = dayjs(this.edate).format(this.dateFormat);

                let survey_res = await this.getSurveyList({
                    sid: this.select_sid,
                    page: this.current,
                    page_size: 20,
                    stime: sdate + ' 00:00:00',
                    etime: edate + ' 23:59:59',
                });

                if (survey_res.code == 100000) {
                    this.survey_list = survey_res.data.list;
                    this.total_count = survey_res.data.total_count;
                    this.total_page = survey_res.data.total_page;
                }

                this.is_loading = false;
            },
            onDateChange() {
                this.current = 1;
                this.do_get_survey_list();
            },
            exportData() {
                let sdate = dayjs(this.sdate).format(this.dateFormat);
                let edate = dayjs(this.edate).format(this.dateFormat);
                let data = {
                    sid: this.select_sid,
                    sdate: sdate + ' 00:00:00',
                    edate: edate + ' 23:59:59',
                };

                this.exportSurveyData(data).then(res => {
                    let uploadExcel = fileName => {
                        const blob = new Blob([res], {
                            type:
                                "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8"
                        });
                        const url = URL.createObjectURL(blob);
                        const aLink = document.createElement("a");
                        aLink.setAttribute("download", fileName);
                        aLink.setAttribute("href", url);
                        document.body.appendChild(aLink);
                        aLink.click();
                        document.body.removeChild(aLink);
                        URL.revokeObjectURL(blob);
                    };
                    uploadExcel("survey_list_" + sdate + '_' + edate + ".xlsx");
                });
            },
        }
    };
</script>

<style scoped>
</style>
