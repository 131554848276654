<template>
    <div class="setting">
        <a-row>
            <a-col :span="8" class="setting_left_zone">
                <a-card :title="$t('setting.setting')" style="width: auto; height: 95vh;">
                    <a-list size="large" :bordered="false" :data-source="data">
                        <template #renderItem="{ item }">
                            <a-list-item @click="chooseType(item.type)" :style="{backgroundColor: current_type == item.type ? '#eee' : '', cursor: 'pointer'}">
                                <div style="display: inline-block; width: 50%; font-size: 16px;">
                                    <AppstoreAddOutlined v-if="item.type == 'general'" />
                                    <InfoCircleOutlined v-if="item.type == 'about'" />
                                    <UserOutlined v-if="item.type == 'account'" />
                                    <span style="margin-left: 15px;">{{ $t('setting.' + item.name) }}</span>
                                </div>
                                <div style="display: inline-block; width: 50%; text-align: right;">
                                    <RightOutlined />
                                </div>
                            </a-list-item>
                        </template>
                    </a-list>
                </a-card>
            </a-col>
            <a-col :span="24" v-if="is_mobile" style="background-color: #fff;">
                <a-tabs v-model:activeKey="current_type" centered size="large">
                    <a-tab-pane key="account" :tab="$t('setting.account')"></a-tab-pane>
                    <a-tab-pane key="general" :tab="$t('setting.general')"></a-tab-pane>
                    <a-tab-pane key="about" :tab="$t('setting.about')"></a-tab-pane>
                </a-tabs>
            </a-col>
            <a-col :lg="{span: 16}" :xs="{span: 24}" class="setting_right_zone">
                <a-card title_bak="$t('setting.' + current_type)" style="width: auto; height: 95vh;">
                    <div v-if="current_type == 'account'">
                        <a-card title="" :bordered="false" style="width: 100%; background-color: #eee; font-size: 16px;">
                            <div>
                                <div style="display: inline-block; width: 50%; font-size: 16px;">
                                    <span style="margin-left: 15px;">{{ $t('setting.logo_label') }}</span>
                                </div>
                                <div style="display: inline-block; width: 50%; text-align: right;">
                                    <img :src="slogo" style="width: 60px; height: auto;"/>
                                </div>
                            </div>
                            <a-divider />
                            <div>
                                <div style="display: inline-block; width: 50%; font-size: 16px;">
                                    <span style="margin-left: 15px;">{{ $t('setting.account') }}</span>
                                </div>
                                <div style="display: inline-block; width: 50%; text-align: right;">
                                    <span>{{ user_name }}</span>
                                </div>
                            </div>
                            <a-divider />
                            <div>
                                <div style="display: inline-block; width: 50%; font-size: 16px;">
                                    <span style="margin-left: 15px;">{{ $t('setting.name_label') }}</span>
                                </div>
                                <div style="display: inline-block; width: 50%; text-align: right;">
                                    <span>{{ chain_name }}</span>
                                </div>
                            </div>
                        </a-card>
                        <a-button @click="doLogout" style="margin-top: 40px; width: 100%; height: 50px; color: red;">{{ $t('setting.logout_btn') }}</a-button>
                    </div>
                    <div v-if="current_type == 'general'">
                        <a-card title="" :bordered="false" style="margin-top: 20px; width: 100%; background-color: #eee; font-size: 16px;">
                            <div @click="chooseLanuage">
                                <div style="display: inline-block; width: 50%; font-size: 16px;">
                                    <span style="margin-left: 15px;">{{ $t('setting.custom_language_label') }}</span>
                                </div>
                                <div style="display: inline-block; width: 50%; text-align: right;">
                                    <span>{{ language_text }}</span>
                                    <RightOutlined />
                                </div>
                            </div>
                        </a-card>
                        <a-card title="" :bordered="false" style="margin-top: 20px; width: 100%; background-color: #eee; font-size: 16px;">
                            <div @click="doNotification">
                                <div style="display: inline-block; width: 50%; font-size: 16px;">
                                    <span style="margin-left: 15px;">{{ $t('setting.notification_label') }}</span>
                                </div>
                                <div style="display: inline-block; width: 50%; text-align: right;">
                                    <span v-if="can_notification == 1">{{ $t('setting.authorized') }}</span>
                                    <span v-if="can_notification == 0">{{ $t('setting.unauthorized') }}</span>
                                    <RightOutlined />
                                </div>
                            </div>
                        </a-card>
                    </div>
                    <div v-if="current_type == 'about'">
                        <a-card title="" :bordered="false" style="margin-top: 20px; width: 100%; background-color: #eee; font-size: 16px;">
                            <div>
                                <div style="display: inline-block; width: 50%; font-size: 16px;">
                                    <span style="margin-left: 15px;">{{ $t('setting.version_label') }}</span>
                                </div>
                                <div style="display: inline-block; width: 50%; text-align: right;">
                                    <span>{{ version + ' (' + build_number + ')' }}</span>
                                </div>
                            </div>
                            <a-divider />
                            <div @click="openPage('privacy')">
                                <div style="display: inline-block; width: 50%; font-size: 16px;">
                                    <span style="margin-left: 15px;">{{ $t('setting.privacy_label') }}</span>
                                </div>
                                <div style="display: inline-block; width: 50%; text-align: right;">
                                    <RightOutlined />
                                </div>
                            </div>
                            <a-divider />
                            <div @click="openPage('terms')">
                                <div style="display: inline-block; width: 50%; font-size: 16px;">
                                    <span style="margin-left: 15px;">{{ $t('setting.terms_label') }}</span>
                                </div>
                                <div style="display: inline-block; width: 50%; text-align: right;">
                                    <RightOutlined />
                                </div>
                            </div>
                        </a-card>
                    </div>
                </a-card>
            </a-col>
        </a-row>
        <a-modal v-model:open="show_language_layer" :footer="null" title="Select Language">
            <a-radio-group v-model:value="language">
                <a-radio value="eng">English</a-radio>
                <a-radio value="chn">中文</a-radio>
            </a-radio-group>
        </a-modal>
    </div>
</template>

<script>
    import {
        RightOutlined,
        AppstoreAddOutlined,
        InfoCircleOutlined,
        UserOutlined,
    } from '@ant-design/icons-vue';

    import { Modal } from 'ant-design-vue';

    export default {
        name: 'SettingPage',
        components: {
            RightOutlined,
            AppstoreAddOutlined,
            InfoCircleOutlined,
            UserOutlined,
        },
        data() {
            return {
                data: [
                    { name: 'account', type: 'account' },
                    { name: 'general', type: 'general' },
                    { name: 'about', type: 'about' },
                ],
                current_type: 'account',
                chain_name: this.$store.state.app.chain_name,
                slogo: this.$store.state.app.slogo,
                sid: this.$store.state.app.sid,
                user_name: this.$store.state.app.user_name,
                show_language_layer: false,
                language: this.$i18n.locale ,
                version: this.$store.state.app.version,
                build_number: this.$store.state.app.build_number,
                is_mobile: this.$store.state.app.is_mobile,
                can_notification: this.$store.state.app.can_notification,
            }
        },
        created() {
        },
        computed: {
            language_text() {
                if (this.language == 'eng') {
                    return 'English';
                } else if (this.language == 'chn') {
                    return '中文';
                } else if (this.language == 'test') {
                    return 'Test';
                }

                return 'Other';
            }
        },
        watch: {
            language() {
                window.localStorage.setItem('merchant_language', this.language);

                if (this.language == 'eng') {
                    this.$i18n.locale = 'eng';
                } else if (this.language == 'chn') {
                    this.$i18n.locale = 'chn';
                }   
            }
        },
        methods: {
            chooseType(type) {
                this.current_type = type;
            },
            doLogout() {
                let that = this;
                Modal.confirm({
                    title: this.$t('setting.logout_tip'),
                    okText: this.$t('common.confirm'),
                    cancelText: this.$t('common.cancel'),
                    maskClosable: true,
                    onOk() {
                        window.localStorage.removeItem('token');
                        window.localStorage.removeItem('user_name');
                        window.localStorage.removeItem('role');
                        window.localStorage.removeItem('sid');
                        window.localStorage.removeItem('slogo');
                        window.localStorage.removeItem('sname');
                        window.localStorage.removeItem('timezone');

                        that.$router.replace({
                            path: '/login'
                        });
                    },
                    onCancel() {
                    },
                });
            },
            openPage(type) {
                if (type == 'privacy') {
                    window.open('https://www.minitable.net/privacypolicy.html');
                } else if (type == 'terms') {
                    window.open('https://www.minitable.net/termofuse.html');
                }
            },
            chooseLanuage() {
                this.show_language_layer = true;
            },
            doNotification() {
                let that = this;
                Notification.requestPermission().then(function (result) {
                    if (result === "granted") {
                        // console.log(result);
                        // that.randomNotification();
                        window.localStorage.setItem('can_notification', 1);
                        that.can_notification = 1;
                    }
                    if (result === "denied") {
                        console.log("User denied notification");
                        window.localStorage.setItem('can_notification', 0);
                    }
                });

                if (navigator.setAppBadge) {
                    navigator.setAppBadge(1);
                }
            },
            randomNotification() {
                var notifTitle = 'test notification';
                var notifBody = "Created by wudi";
                var notifImg = "https://d3puj4wh202ha4.cloudfront.net/minipass/images/group.jpg";
                var options = {
                    body: notifBody,
                    icon: notifImg
                };

                var notif = new Notification(notifTitle, options);

                notif.onclick = function () {
                    window.open('https://minitable.net');
                };
            }
        }
    }
</script>

<style scoped>
    @media screen and (max-width: 768px) {
        .setting {
            width: 98vw;
            overflow-x: hidden;
            height: 90vh;
            overflow-y: hidden;
        }
        .setting_left_zone {
            display: none;
        }
    }
</style>
