import axiosApi from '../../kits/axiosApi';

// initial state
const state = {
};

// getters
const getters = {
};

// actions
const actions = {
    getSurveySetting({ rootState }, params) {
        let aj_host = rootState.aj_host;

        return new Promise((resolve, reject) => {
            axiosApi
                .get(aj_host + '/weapp/master/survey/setting', { params })
                .then((res) => {
                    resolve(res.data.data);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    },
    getSurveyList({ rootState }, params) {
        let aj_host = rootState.aj_host;

        return new Promise((resolve, reject) => {
            axiosApi
                .get(aj_host + '/weapp/master/survey/list', { params })
                .then((res) => {
                    resolve(res.data.data);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    },
    getSurveyData({ rootState }, params) {
        let aj_host = rootState.aj_host;

        return new Promise((resolve, reject) => {
            axiosApi
                .get(aj_host + '/weapp/master/survey/data', { params })
                .then((res) => {
                    resolve(res.data.data);
                })
                .catch((err) => {
                    reject(err);
                });
        });
    },
    exportSurveyData({ rootState }, params) {
        let aj_host = rootState.aj_host;

        return new Promise((resolve, reject) => {
            axiosApi({
                method: 'get',
                url: aj_host + '/weapp/master/survey/export',
                params: params,
                responseType: 'blob'
            })
                .then((res) => {
                    resolve(res.data)
                })
                .catch((err) => {
                    reject(err)
                })
        });
    },
};

// mutations
const mutations = {
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
};
