/* eslint-disable no-console */

import { register } from 'register-service-worker';
import axiosApi from './kits/axiosApi';

let aj_host = '';
if (window.location.hostname.indexOf('.net') > -1) {
    aj_host = 'https://master.minitable.net';
} else {
    aj_host = 'https://master.minitable.link';
}

if (window.location.hostname === '127.0.0.1' || window.location.hostname === 'localhost') {
    aj_host = 'http://127.0.0.1:7272';
}

let chain_id = window.localStorage.getItem('chain_id');
let token = window.localStorage.getItem('token');
let can_notification = window.localStorage.getItem('can_notification') || 0;

if (process.env.NODE_ENV === 'production' || process.env.NODE_ENV === 'development') {
    register(`${process.env.BASE_URL}service-worker.js`, {
        ready (registration) {
            if (!chain_id || !token || can_notification == 0) {
                return;
            }

            self.addEventListener("push", function (event) {
                const payload = event.data ? event.data.text() : "no payload";
                console.log('push event', event);
                event.waitUntil(
                    self.registration.showNotification("New Message", {
                        body: payload,
                        icon: "https://d3puj4wh202ha4.cloudfront.net/minipass/images/group.jpg"
                    }),
                );
            });

            registration.pushManager.getSubscription()
                .then(function(subscription) {
                    // If a subscription was found, return it.
                    if (subscription) {
                        return subscription;
                    }

                    // send notifications that don't have a visible effect for the user).
                    return registration.pushManager.subscribe({
                        userVisibleOnly: true,
                        applicationServerKey: urlBase64ToUint8Array('BLErpzKsnEFiGT5In3BudejtvGP_ZnRTkoY_Yq4tMEpKs7ENntNLDhblLjCuvGMB5pTU-MNUygGOBQrjnw4PIqI')
                    });
                })
                .then(function(subscription) {
                    axiosApi.post(aj_host + '/weapp/master/survey/register', { subscription, chain_id })
                        .then(res => {
                            console.log(res);
                        })
                        .catch(err => {
                            console.log(err);
                        });
                });
        },
        registered () {
            console.log('Service worker has been registered.')
        },
        cached () {
            console.log('Content has been cached for offline use.')
        },
        updatefound () {
            console.log('New content is downloading.')
        },
        updated () {
            console.log('New content is available; please refresh.')
        },
        offline () {
            console.log('No internet connection found. App is running in offline mode.')
        },
        error (error) {
            console.error('Error during service worker registration:', error)
        }
    })
}

function urlBase64ToUint8Array(base64String) {
    var padding = '='.repeat((4 - base64String.length % 4) % 4);
    var base64 = (base64String + padding)
        .replace(/-/g, '+')
        .replace(/_/g, '/');
   
    var rawData = window.atob(base64);
    var outputArray = new Uint8Array(rawData.length);
   
    for (var i = 0; i < rawData.length; ++i) {
        outputArray[i] = rawData.charCodeAt(i);
    }

    return outputArray;
}

